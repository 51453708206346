import React from 'react';

type LayoutVariant =
  | 'fourTwo'
  | 'twoColumns'
  | 'fourColumns'
  | 'threeColumns'
  | 'singleColumn'
  | 'centeredContent'
  | 'staggeredLayout';

interface LayoutProps {
  variant: LayoutVariant;
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ variant, children }) => {
  let classes = '';

  switch (variant) {
    case 'fourTwo':
      classes = 'grid grid-cols-2 lg:grid-cols-4 gap-2';
      break;
    case 'twoColumns':
      classes = 'grid md:grid-cols-2 gap-4';
      break;
    case 'fourColumns':
      classes = 'grid lg:grid-cols-4 gap-4';
      break;
    case 'threeColumns':
      classes = 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4';
      break;
    case 'singleColumn':
      classes = 'w-full';
      break;
    case 'centeredContent':
      classes = 'max-w-screen-lg mx-auto';
      break;
    case 'staggeredLayout':
      classes = 'grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8';
      break;
    default:
      break;
  }

  return <div className={classes}>{children}</div>;
};

export default Layout;
