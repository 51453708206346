// src/atoms/Input.tsx
import React, { FC, InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: 'default' | 'readOnly';
}

const Input: FC<InputProps> = ({ variant = 'default', ...rest }) => {
  const baseClasses =
    'w-full p-4 text-base border rounded-md border-primary-800 focus:outline-none focus:border-primary-500';

  const readOnlyClasses = variant === 'readOnly' ? 'cursor-pointer' : '';

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (rest.onFocus) {
      rest.onFocus(e);
    }
    // You can add more custom behavior here if needed
  };

  return (
    <input
      {...rest}
      className={`${baseClasses} ${readOnlyClasses}`}
      readOnly={variant === 'readOnly'}
      onFocus={handleFocus}
    />
  );
};

export default Input;
