import React, { useState } from 'react';
import Label from '../../atoms/Label';
import Input from '../../atoms/Input';

interface UploadFieldProps {
  id: string;
  label: string;
  helperText?: string;
  multiple?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadField: React.FC<UploadFieldProps> = ({
  id,
  label,
  helperText,
  multiple,
  onChange,
}) => {
  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      const fileArray = Array.from(fileList);
      setFiles((prevFiles) => [...prevFiles, ...fileArray]);
      onChange && onChange(event);
    }
  };

  return (
    <div className="max-w-md" id={id}>
      <Label id={id} text={label} />
      <Input
        type="file"
        id={id}
        multiple={multiple}
        onChange={handleFileChange}
      />
      {files.length > 0 && (
        <div className="flex flex-wrap mt-2">
          {files.map((file) => (
            <div key={file.name} className="w-1/4 p-2">
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="w-full h-auto"
              />
            </div>
          ))}
        </div>
      )}
      {helperText && <p className="mt-2 text-sm text-gray-500">{helperText}</p>}
    </div>
  );
};

export default UploadField;
