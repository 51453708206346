import React, { FC, InputHTMLAttributes } from 'react';
import Label from '../../atoms/Label';
import Input from '../../atoms/Input';

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}

const TextField: FC<TextFieldProps> = ({
  id,
  label,
  type,
  value,
  onChange,
  placeholder,
  ...restProps
}) => {
  return (
    <div className="w-full" id={id}>
      <Label id={id} text={label} />
      <Input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...restProps}
      />
    </div>
  );
};

export default TextField;
