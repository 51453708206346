import React from 'react';
import logo from '../../../assets/svg/logo.svg';

type LogoProps = {
  className?: string;
};

export default function Logo({ className }: LogoProps) {
  return (
    <div>
      <img src={logo} alt="logo" className={`h-10 w-auto ${className}`} />
    </div>
  );
}
