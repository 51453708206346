import React from 'react';

type SectionHeaderProps = {
  overline?: string;
  title: string;
  subtitle: string;
  className?: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({
  overline,
  title,
  subtitle,
  className = '',
  ...restProps
}) => (
  <div className={`mb-12 max-w-prose text-left ${className}`} {...restProps}>
    <h2 className="{`${className}`} mb-4 text-sm uppercase tracking-wide font-bold">
      {overline}
    </h2>
    <h3 className="{`${className}`} mb-4 text-3xl tracking-tight font-extrabold">
      {title}
    </h3>
    <p className="mb-4 opacity-85 sm:text-xl">{subtitle}</p>
  </div>
);

export default SectionHeader;
