import React from 'react';
import {
  FaTools,
  FaLeaf,
  FaIndustry,
  FaRecycle,
  FaCouch,
  FaRegThumbsUp,
} from 'react-icons/fa';
import IconBox from '../../molecules/IconBox';
import ContentWrapper from '../../atoms/ContentWrapper';
import SectionWrapper from '../../atoms/SectionWrapper';
import SectionHeader from '../../molecules/SectionHeader';
import Layout from '../../atoms/Layout';

const AboutSection: React.FC = () => {
  return (
    <SectionWrapper>
      <ContentWrapper>
        <SectionHeader
          overline="About"
          title="Benefits of Eco-Waste Clear"
          subtitle="Eco-Waste Clear is a waste management company that offers a wide range of services for both domestic and commercial clients. With a focus on sustainability and efficiency, Eco-Waste Clear is the ideal partner for all waste management needs."
        />
        <Layout variant="threeColumns">
          <IconBox
            icon={<FaTools />}
            title="For Households and Businesses"
            description="With services ranging from domestic and garden waste to commercial, retail, and construction waste management, Eco-Waste Clear offers a one-stop solution for all waste management needs. Whether you're a homeowner or a business, there's a tailored solution for you."
          />
          <IconBox
            icon={<FaLeaf />}
            title="Our Commitment to Sustainability"
            description="Eco-Waste Clear's focus on eco-friendly methods across all services ensures that clients are part of a responsible waste management system. From recycling to responsible disposal, every action is aligned with environmental stewardship."
          />
          <IconBox
            icon={<FaIndustry />}
            title="Specialized Services for Businesses"
            description="Eco-Waste Clear understands the unique needs of different industries, including warehouses, retail, and commercial sectors. Tailored waste management strategies make the process more efficient and aligned with specific business goals."
          />
          <IconBox
            icon={<FaRecycle />}
            title="Seizing Opportunities for Recycling and Reuse"
            description="With a focus on turning waste into opportunities for recycling and reuse, Eco-Waste Clear helps clients contribute to a circular economy. This not only benefits the environment but can also lead to potential cost savings and resource recovery."
          />
          <IconBox
            icon={<FaCouch />}
            title="Responsibly Disposing of Bulky Furniture"
            description="Eco-Waste Clear's dedicated service for furniture disposal means that large, bulky items are handled with care and responsibility, ensuring that materials are recycled or disposed of in an eco-friendly manner."
          />
          <IconBox
            icon={<FaRegThumbsUp />}
            title="Dedicated to serving the community"
            description="By partnering with Eco-Waste Clear, clients signal their commitment to sustainable practices. This can enhance the public image and brand reputation, building trust and loyalty among customers and stakeholders who value environmental responsibility."
          />
        </Layout>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default AboutSection;
