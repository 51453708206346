import React from 'react';
import Label from '../../atoms/Label';
import Textarea from '../../atoms/Textarea';

interface TextareaFieldProps {
  id?: string;
  label: string;
  placeholder: string;
  required?: boolean;
  rows?: number;
  value: string;
  onChange: (value: string) => void;
}

const TextareaField: React.FC<TextareaFieldProps> = ({
  id,
  label,
  placeholder,
  required = false,
  rows = 4,
  value,
  onChange,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="w-full" id={id}>
      <Label id={id || ''} text={label} />
      <Textarea
        id={id}
        placeholder={placeholder}
        required={required}
        rows={rows}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextareaField;
