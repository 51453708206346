import React, { ReactNode } from 'react';

interface IconBoxProps {
  icon: ReactNode;
  title: string;
  description: string;
  titleClass?: string;
  descriptionClass?: string;
}

const IconBox: React.FC<IconBoxProps> = ({
  icon,
  title,
  description,
  titleClass = 'mb-4 mt-2 text-xl leading-tight font-semibold',
  descriptionClass = 'text-primary-800 text-md',
}) => {
  return (
    <div className="mb-8">
      <div className="inline-flex justify-center text-3xl items-center mb-4 w-20 h-20 rounded-full border-2 border-primary-600 bg-primary-100">
        <div className="rounded text-primary-600">{icon}</div>{' '}
        {/* Here we render the icon */}
      </div>
      <h3 className={titleClass}>{title}</h3>
      <p className={descriptionClass}>{description}</p>
    </div>
  );
};

export default IconBox;
