import React from 'react';

interface CheckboxProps {
  id: string;
  label: string;
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  isChecked,
  onChange,
}) => (
  <div className="flex items-center gap-4">
    <input
      type="checkbox"
      id={id}
      checked={isChecked}
      onChange={onChange}
      className="appearance-none mr-2 p-3 rounded border-2 border-primary-500 hover:bg-primary-300 checked:bg-primary-700 checked:hover:bg-primary-900"
    />
    <label htmlFor={id} className="text-md">
      {label}
    </label>
  </div>
);

export default Checkbox;
