import React, { ReactElement, ReactNode } from 'react';

type ListItemProps = {
  icon: ReactNode;
  label: string;
  className?: string;
};

const ListItem: React.FC<ListItemProps> = ({
  icon,
  label,
  className = '',
}): ReactElement => {
  return (
    <div className={`flex items-center space-x-4 ${className}`}>
      <span className="text-xl p-2 bg-tertiary-300 text-tertiary-800 rounded-full">
        {icon}
      </span>
      <span className="text-md text-primary-200 font-medium">{label}</span>
    </div>
  );
};

export default ListItem;
