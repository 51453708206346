import React from 'react';
import { Link } from 'react-scroll';
import { Ri24HoursLine, RiTeamFill, RiRecycleFill } from 'react-icons/ri';
import SectionWrapper from '../../atoms/SectionWrapper';
import ContentWrapper from '../../atoms/ContentWrapper';
import ListItem from '../../molecules/ListItem/ListItem';
import Button from '../../atoms/Button';

const HeroSection: React.FC = () => {
  return (
    <div className="background-image">
      <SectionWrapper>
        <ContentWrapper>
          <div className="mt-20 lg:max-w-screen-md shadow-lg">
            <div className="rounded-xl space-y-4 text-left p-8 lg:p-10 bg-slate-950/75 border-2 border-primary-700 backdrop-blur-sm">
              <p className="uppercase tracking-wide text-md text-primary-200">
                Welcome to Eco-Waste Clear
              </p>
              <h1 className="pt-2 text-5xl lg:text-6xl font-semibold text-primary-300">
                Your Green Solution
                <br />
                <span className="text-5xl lg:text-6xl text-tertiary-200 font-bold">
                  for Waste Disposal
                </span>
              </h1>
              <h2 className="lg:text-lg text-primary-100">
                Eco-Waste Clear provides waste management services offering
                flexible collections and always responsible disposals.
              </h2>
              <p className="text-primary-100 font-bold">
                Professional, affordable and reliable.
              </p>
              <hr />
              <ul className="space-y-4">
                <ListItem icon={<Ri24HoursLine />} label="24/7 collections" />
                <ListItem
                  icon={<RiTeamFill />}
                  label="Single item collections"
                />
                <ListItem
                  icon={<RiRecycleFill />}
                  label="Cheaper than a skip!"
                />
              </ul>
              <hr />
              <div className="flex flex-col space-y-2 lg:flex lg:gap-2 pt-8">
                <Link
                  to="quote"
                  spy={true}
                  smooth={true}
                  offset={-10}
                  duration={800}
                >
                  <Button size="large" variant="filled" isFullWidth={true}>
                    Book a Collection
                  </Button>
                </Link>
                <Link
                  to="quote"
                  spy={true}
                  smooth={true}
                  offset={-10}
                  duration={800}
                >
                  <Button size="large" variant="outline" isFullWidth={true}>
                    Learn More
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </SectionWrapper>
    </div>
  );
};

export default HeroSection;
