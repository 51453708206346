import React from 'react';

type SectionWrapperProps = {
  className?: string;
  children: React.ReactNode;
};

const SectionWrapper: React.FC<SectionWrapperProps> = ({ children }) => (
  <div className="mx-auto max-w-screen-lg px-4 ">{children}</div>
);

export default SectionWrapper;
