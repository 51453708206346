import React from 'react';
import SectionWrapper from '../../atoms/SectionWrapper';
import ContentWrapper from '../../atoms/ContentWrapper';
import SectionHeader from '../../molecules/SectionHeader';
import { FaPhone, FaAt, FaLocationArrow } from 'react-icons/fa';

export default function ContactSection() {
  return (
    <SectionWrapper>
      <ContentWrapper>
        <SectionHeader
          overline="Contact"
          title="Get in touch"
          subtitle="We're here to help. If you have any questions about our services, please get in touch with us."
        />
        <div className="space-y-4">
          <div className="bg-primary-100 border-2 border-primary-600 rounded-md p-4">
            <div className="flex items-center p-4">
              <div className="flex flex-col gap-1">
                <h3 className="font-bold uppercase text-primary-800">
                  Call Us
                </h3>
                <a href="tel:02081509518" className="text-xl">
                  0208 150 9518
                </a>
                <p>Lines open Mon-Fri from 9am to 6pm</p>
              </div>
            </div>
          </div>
          <div className="bg-primary-100 border-2 border-primary-600 rounded-md p-4">
            <div className="flex items-center p-4">
              <div className="flex flex-col gap-1">
                <h3 className="font-bold uppercase text-primary-800">
                  Email Us
                </h3>
                <a href="mailto:info@ecowasteclear.co.uk" className="text-xl">
                  info@ecowasteclear.co.uk
                </a>
                <p>We aim respond within 24 hours</p>
              </div>
            </div>
          </div>
          <div className="bg-primary-100 border-2 border-primary-600 rounded-md p-4">
            <div className="flex items-center p-4">
              <div className="flex flex-col gap-1">
                <h3 className="font-bold uppercase text-primary-800">
                  Office Address
                </h3>
                <p className="text-xl">
                  Unit 12/13 Brownings Farm, Gravel Lane, Chigwell, Essex IG7
                  6DQ
                </p>
                <p>Office open Mon-Fri from 8am to 7pm</p>
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </SectionWrapper>
  );
}
