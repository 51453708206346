import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { FaBars, FaTimes } from 'react-icons/fa';
import Logo from '../../atoms/Logo';

interface NavbarProps {
  links: { to: string; label: string }[];
}

const Navbar: React.FC<NavbarProps> = ({ links }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="fixed top-0 uppercase backdrop-blur-md w-full z-10 bg-white/75 shadow">
      <div className="flex w-full mx-auto py-2 px-6 justify-center items-center bg-slate-500/50">
        <span className="mr-2 font-bold">Call Us:</span>
        <a href="tel:02081509518" className="underline">
          0208 150 9518
        </a>
      </div>
      <hr className="border-primary-700" />
      <div className="container mx-auto px-4 py-4 max-w-screen-lg">
        <div className="flex items-center justify-between">
          <ScrollLink
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <Logo className="cursor-pointer"></Logo>
          </ScrollLink>
          <div className="hidden md:flex space-x-8">
            {links.map((link) => (
              <ScrollLink
                key={link.to}
                activeClass="text-primary-600 font-bold underline"
                className="text-primary-800 font-medium border-b border-transparent hover:text-primary-500 hover:border-primary-500 cursor-pointer text-center"
                to={link.to}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                {link.label}
              </ScrollLink>
            ))}
          </div>
          <div className="md:hidden">
            <button
              className="p-2 text-xl text-primary-800 hover:text-primary-500 focus:outline-none"
              onClick={toggleMobileMenu}
            >
              {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>
        </div>
        {isMobileMenuOpen && (
          <div className="md:hidden top-16 mt-4 w-full border border-primary-600">
            <div className="flex flex-col items-stretch text-center divide-y divide-primary-600 uppercase">
              {links.map((link) => (
                <ScrollLink
                  key={link.to}
                  activeClass="text-primary-600 bg-primary-200 font-bold"
                  className="p-4 bg-primary-100 text-primary-800 hover:text-primary-300 hover:underline hover:bg-primary-700 cursor-pointer"
                  to={link.to}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggleMobileMenu}
                >
                  {link.label}
                </ScrollLink>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
