import React from 'react';

interface ImageBoxProps {
  imageSrc: string;
  title: string;
  description: string;
  titleClass?: string;
  descriptionClass?: string;
}

const ImageBox: React.FC<ImageBoxProps> = ({
  imageSrc,
  title,
  description,
  titleClass = 'mb-2 text-xl font-bold text-secondary-900',
  descriptionClass = 'text-secondary-800 text-md',
}) => {
  return (
    <div className="mb-8">
      <div className="flex justify-center items-center mb-4 w-50 h-auto">
        <img src={imageSrc} className="rounded" alt={title} />
      </div>
      <h3 className={titleClass}>{title}</h3>
      <p className={descriptionClass}>{description}</p>
    </div>
  );
};

export default ImageBox;
