import React from 'react';
import Label from '../../atoms/Label';
import Radio from '../../atoms/Radio';

interface RadioGroupProps {
  id: string;
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  options: { label: string; value: string }[];
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  id,
  label,
  value,
  onChange,
  options,
}) => {
  return (
    <div className="max-w-md" id={id}>
      <Label id={id} text={label} />
      <fieldset className="flex max-w-md flex-col gap-4">
        {options.map((option) => (
          <div key={option.value} className="flex items-center gap-2">
            <Radio
              id={option.value}
              value={option.value}
              checked={value === option.value}
              onChange={() => onChange(option.value)}
            />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </fieldset>
    </div>
  );
};

export default RadioGroup;
