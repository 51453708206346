import React from 'react';

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Radio: React.FC<RadioProps> = ({ ...props }) => {
  return (
    <input
      type="radio"
      {...props}
      className="appearance-none mr-2 p-3 rounded-full border-2 border-primary-500 hover:bg-primary-300 checked:bg-primary-700 checked:hover:bg-primary-900"
    />
  );
};

export default Radio;
