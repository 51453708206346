import React, { useState, FC, useRef, useEffect } from 'react';
import Datepicker from 'tailwind-datepicker-react';
import Label from '../../atoms/Label';
import Input from '../../atoms/Input';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';

interface DatepickerFieldProps {
  id: string;
  label: string;
  onChange: (date: Date) => void;
  value: Date | null;
  placeholder: string;
}

const DatepickerField: FC<DatepickerFieldProps> = ({
  id,
  label,
  onChange,
  value,
  placeholder,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const options = {
    title: 'Select a date',
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    inputDateFormatProp: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
    theme: {
      background: '',
      todayBtn: '',
      clearBtn: '',
      icons: '',
      text: '',
      disabledText: '',
      input: '',
      inputIcon: '',
      selected: '',
    },
    icons: {
      // () => ReactElement | JSX.Element
      prev: () => <AiFillCaretLeft />,
      next: () => <AiFillCaretRight />,
    },
    datepickerClassNames: 'top-12',
    defaultDate: new Date(),
    language: 'en',
  };

  const handleDateChange = (date: Date) => {
    onChange(date);
    handleClose(); // Close the date picker when a date is selected
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className="w-full" id={id}>
      <Label id={id} text={label} />
      <div ref={wrapperRef} className="relative">
        <Datepicker
          options={options}
          onChange={handleDateChange}
          show={show}
          setShow={handleClose}
        >
          <div className="relative">
            <Input
              id={id}
              variant="readOnly"
              placeholder={placeholder}
              value={value?.toLocaleDateString() || ''}
              onFocus={() => setShow(true)}
            />
          </div>
        </Datepicker>
      </div>
    </div>
  );
};

export default DatepickerField;
