import React, { TextareaHTMLAttributes } from 'react';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: React.FC<TextareaProps> = ({ onChange, ...restProps }) => {
  return (
    <textarea
      className="resize-y border rounded-md w-full p-4"
      onChange={onChange}
      {...restProps}
    />
  );
};

export default Textarea;
