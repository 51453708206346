import React from 'react';
import Navbar from '../../components/organisms/Navbar';
import HeroSection from '../../components/organisms/HeroSection';
import ServicesSection from '../../components/organisms/ServicesSection';
import AboutSection from '../../components/organisms/AboutSection';
import PricingSection from '../../components/organisms/PricingSection';
import FAQSection from '../../components/organisms/FAQSection';
import ContactSection from '../../components/organisms/ContactSection';
import QuoteForm from '../../components/organisms/QuoteForm';
import Footer from '../../components/organisms/Footer';

const LandingPage: React.FC = () => {
  const links = [
    { to: 'about', label: 'About' },
    { to: 'services', label: 'Services' },
    { to: 'pricing', label: 'Pricing' },
    { to: 'faq', label: 'FAQ' },
    { to: 'quote', label: 'Quote' },
    { to: 'contact', label: 'Contact' },
  ];

  return (
    <>
      <Navbar links={links} />
      <div id="home">
        <HeroSection />
      </div>
      <div id="about">
        <AboutSection />
      </div>
      <div id="services">
        <ServicesSection />
      </div>
      <div id="pricing">
        <PricingSection />
      </div>
      <div id="faq">
        <FAQSection />
      </div>
      <div id="quote">
        <QuoteForm />
      </div>
      <div id="contact">
        <ContactSection />
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
