import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import SectionWrapper from '../../atoms/SectionWrapper';

export default function Footer() {
  return (
    <div className="bg-primary-800">
      <SectionWrapper>
        <footer className="p-4 pt-16">
          <div className="mx-auto max-w-screen-lg">
            <div className="md:flex md:justify-between">
              <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                <div>
                  <h2 className="mb-6 text-sm font-semibold text-primary-200 uppercase ">
                    Services
                  </h2>
                  <ul className="text-primary-100 ">
                    <li className="mb-4">
                      <a href="#" className="hover:underline">
                        Waste Collection
                      </a>
                    </li>
                    <li>
                      <a href="#" className="hover:underline">
                        Recycling
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="mb-6 text-sm font-semibold text-primary-200 uppercase ">
                    Website
                  </h2>
                  <ul className="text-primary-100 ">
                    <li className="mb-4">
                      <a
                        href="https://github.com/themesberg/flowbite"
                        className="hover:underline "
                      >
                        Online Quote
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://discord.gg/4eeurUVvTy"
                        className="hover:underline"
                      >
                        FAQs
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="mb-6 text-sm font-semibold text-primary-200 uppercase ">
                    About
                  </h2>
                  <ul className="text-primary-100 ">
                    <li className="mb-4">
                      <a href="#" className="hover:underline">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="#" className="hover:underline">
                        Terms &amp; Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr className="my-6 border-primary-200 sm:mx-auto  lg:my-8" />
            <div className="sm:flex sm:items-center sm:justify-between">
              <span className="text-sm text-primary-200 sm:text-center ">
                © 2023{' '}
                <a href="https://flowbite.com" className="hover:underline">
                  Eco-Waste Clear
                </a>
                . All Rights Reserved.
              </span>
            </div>
          </div>
        </footer>
      </SectionWrapper>
    </div>
  );
}
