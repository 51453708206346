import React from 'react';
import ImageBox from '../../molecules/ImageBox';
import ContentWrapper from '../../atoms/ContentWrapper';
import SectionWrapper from '../../atoms/SectionWrapper';
import SectionHeader from '../../molecules/SectionHeader';
import Layout from '../../atoms/Layout';

import feature1 from '../../../assets/img/feature-1.png';
import feature2 from '../../../assets/img/feature-2.png';
import feature3 from '../../../assets/img/feature-3.png';
import feature4 from '../../../assets/img/feature-4.png';
import feature5 from '../../../assets/img/feature-5.png';
import feature6 from '../../../assets/img/feature-6.png';
import feature7 from '../../../assets/img/feature-7.jpg';
import feature8 from '../../../assets/img/feature-8.png';

const ServicesSection: React.FC = () => {
  const features = [
    {
      imageSrc: feature1,
      title: 'Domestic',
      description: 'Comprehensive household waste collection.',
    },
    {
      imageSrc: feature2,
      title: 'Garden',
      description: 'Professional garden waste collection and disposal.',
    },
    {
      imageSrc: feature3,
      title: 'Warehouses',
      description: 'Expert solutions for warehouse waste removal.',
    },
    {
      imageSrc: feature4,
      title: 'Retail',
      description: 'Tailored services for modern retail waste needs.',
    },
    {
      imageSrc: feature5,
      title: 'Recyclable',
      description: 'From electronics to plastics we take care of it.',
    },
    {
      imageSrc: feature6,
      title: 'Construction',
      description: 'Dedicated services for construction sites.',
    },
    {
      imageSrc: feature7,
      title: 'Commercial',
      description: 'Seamless commercial waste management.',
    },
    {
      imageSrc: feature8,
      title: 'Furniture',
      description: 'Eco-friendly disposal for old furniture.',
    },
  ];

  return (
    <div className="bg-primary-100">
      <SectionWrapper>
        <ContentWrapper>
          <SectionHeader
            overline="Services"
            title="Flexible Collection, Responsible Disposal"
            subtitle="We offer a wide range of waste management services to meet your needs."
            className="text-primary-900"
          />
          <Layout variant="fourColumns">
            {features.map((feature, index) => (
              <div key={index}>
                <ImageBox
                  imageSrc={feature.imageSrc}
                  title={feature.title}
                  description={feature.description}
                />
              </div>
            ))}
          </Layout>
        </ContentWrapper>
      </SectionWrapper>
    </div>
  );
};

export default ServicesSection;
