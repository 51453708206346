import React, { FC, SelectHTMLAttributes } from 'react';
import Label from '../../atoms/Label';

interface Option {
  value: string;
  label: string;
}

interface SelectFieldProps extends SelectHTMLAttributes<HTMLSelectElement> {
  id: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Option[];
  placeholder: string;
}

const SelectField: FC<SelectFieldProps> = ({
  id,
  label,
  value,
  onChange,
  options,
  placeholder,
  ...restProps
}) => {
  return (
    <div className="max-w-md" id={id}>
      <div className="mb-2 block">
        <Label id={id} text={label}>
          {label}
        </Label>
      </div>
      <select
        className="w-full p-4 text-base border rounded-md border-primary-800 focus:outline-none focus:border-primary-500"
        value={value}
        onChange={onChange}
        {...restProps}
      >
        <option value="">{placeholder}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
