{
  "name": {
    "id": "name",
    "label": "Name",
    "placeholder": "Please provide your full name"
  },
  "phone": {
    "id": "phone",
    "label": "Phone",
    "placeholder": "Please enter your mobile number"
  },
  "email": {
    "id": "email",
    "label": "Email",
    "placeholder": "Please enter your email address"
  },
  "customerType": {
    "id": "customerType",
    "label": "Customer Type",
    "placeholder": "Select customer type",
    "options": [
      { "value": "Domestic", "label": "Domestic" },
      { "value": "Commercial", "label": "Commercial" }
    ]
  },
  "postCode": {
    "id": "postCode",
    "label": "Postcode",
    "placeholder": "Enter your postcode"
  },
  "locationType": {
    "id": "locationType",
    "label": "Location Type",
    "placeholder": "Select location type",
    "options": [
      { "value": "House", "label": "House" },
      { "value": "Apartment Building", "label": "Apartment Building" },
      { "value": "Office Building", "label": "Office Building" },
      { "value": "Shopping Centre", "label": "Shopping Centre" },
      { "value": "School", "label": "School" },
      { "value": "Hospital", "label": "Hospital" },
      { "value": "Construction Site", "label": "Construction Site" }
    ]
  },
  "parking": {
    "id": "parking",
    "label": "Parking",
    "placeholder": "Describe parking situation",
    "options": [
      { "value": "Street", "label": "Street" },
      { "value": "Driveway", "label": "Driveway" },
      { "value": "Garage", "label": "Garage" },
      { "value": "Underground", "label": "Underground" }
    ]
  },
  "collectionType": {
    "id": "collectionType",
    "label": "Collection Type",
    "placeholder": "Select collection type",
    "options": [
      { "value": "Item Collection", "label": "Item Collection" },
      { "value": "Waste Collection", "label": "Waste Collection" },
      { "value": "Recycling", "label": "Recycling" },
      { "value": "Mixed", "label": "Mixed" }
    ]
  },
  "collectionItems": {
    "id": "collectionItems",
    "label": "Collection Items",
    "placeholder": "List items for collection",
    "options": [
      { "value": "Household Furniture", "label": "Household Furniture" },
      { "value": "Washing Machine", "label": "Washing Machine" },
      { "value": "Fridge/Freezer", "label": "Fridge/Freezer" },
      { "value": "Mattress", "label": "Mattress" },
      { "value": "Carpet", "label": "Carpet" },
      { "value": "TV", "label": "TV" },
      { "value": "Computer", "label": "Computer" },
      { "value": "Printer", "label": "Printer" },
      { "value": "Monitor", "label": "Monitor" },
      { "value": "Bicycle", "label": "Bicycle" },
      { "value": "Garden Waste", "label": "Garden Waste" },
      { "value": "Mixed Waste", "label": "Mixed Waste" }
    ]
  },
  "collectionVolume": {
    "id": "collectionVolume",
    "label": "Collection Volume",
    "placeholder": "Enter collection volume",
    "options": [
      { "value": "1/4 Load - £90", "label": "1/4 Load - £90" },
      { "value": "1/2 Load - £120", "label": "1/2 Load - £120" },
      { "value": "3/4 Load - £250", "label": "3/4 Load - £250" },
      { "value": "Full Load - £300", "label": "Full Load - £300" }
    ]
  },
  "collectionDate": {
    "id": "collectionDate",
    "label": "Collection Date",
    "placeholder": "Pick a collection date"
  },
  "additionalInformation": {
    "id": "additionalInformation",
    "label": "Additional Information",
    "placeholder": "Enter additional information"
  },
  "imageUrls": {
    "id": "imageUrls",
    "label": "Upload Images",
    "placeholder": "Please upload images"
  }
}
