export async function sendToAPI(formData: FormData): Promise<boolean> {
  try {
    const response = await fetch('/api/notion', {
      method: 'POST',
      body: formData,
    });
    return response.ok;
  } catch (error) {
    return false;
  }
}
