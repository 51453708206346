import React from 'react';
import Label from '../../atoms/Label';
import Checkbox from '../../atoms/Checkbox';

interface CheckboxProps {
  value: string;
  label: string;
}

interface CheckboxGroupProps {
  id: string;
  label: string;
  selectedItems: string[];
  onChange: (newItems: string[]) => void;
  options: CheckboxProps[];
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  id,
  label,
  selectedItems,
  onChange,
  options,
}) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.id;
    const newSelectedItems = selectedItems.includes(value)
      ? selectedItems.filter((item) => item !== value)
      : [...selectedItems, value];

    onChange(newSelectedItems);
  };

  return (
    <div className="max-w-md" id={id}>
      <Label id={id} text={label} />
      <div className="flex flex-col gap-4">
        {options.map((option) => (
          <Checkbox
            key={option.value}
            id={option.value}
            label={option.label}
            isChecked={selectedItems.includes(option.value)}
            onChange={handleCheckboxChange}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckboxGroup;
