import React, { FC, ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'filled' | 'outline';
  color?: 'primary' | 'secondary' | 'danger';
  size?: 'small' | 'medium' | 'large';
  isBlock?: boolean;
  isFullWidth?: boolean;
}

const Button: FC<ButtonProps> = ({
  variant = 'filled',
  color = 'primary',
  size = 'medium',
  isBlock = false,
  isFullWidth = false,
  children,
  ...rest
}) => {
  const baseStyles =
    'rounded focus:outline-none focus:ring focus:ring-opacity-50';
  let colorStyles = '';
  let sizeStyles = '';
  let layoutStyles = '';

  // Color styles based on variant and color prop
  switch (variant) {
    case 'filled':
      switch (color) {
        case 'primary':
          colorStyles = 'text-white bg-primary-500 hover:bg-primary-600';
          break;
        case 'secondary':
          colorStyles = 'text-white bg-gray-500 hover:bg-gray-600';
          break;
        case 'danger':
          colorStyles = 'text-white bg-red-500 hover:bg-red-600';
          break;
        default:
          break;
      }
      break;
    case 'outline':
      switch (color) {
        case 'primary':
          colorStyles =
            'text-primary-500 border border-primary-500 hover:border-primary-500 hover:text-primary-300 hover:bg-primary-100/50';
          break;
        case 'secondary':
          colorStyles =
            'text-gray-500 border border-gray-500 hover:bg-gray-500 hover:text-white';
          break;
        case 'danger':
          colorStyles =
            'text-red-500 border border-red-500 hover:bg-red-500 hover:text-white';
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  // Size styles
  switch (size) {
    case 'small':
      sizeStyles = 'px-2 py-1 text-xs';
      break;
    case 'medium':
      sizeStyles = 'px-4 py-2 text-sm';
      break;
    case 'large':
      sizeStyles = 'px-6 py-3 text-lg';
      break;
    default:
      break;
  }

  // Layout styles
  if (isBlock) {
    layoutStyles += ' block';
  } else {
    layoutStyles += ' inline-block';
  }

  if (isFullWidth) {
    layoutStyles += ' w-full';
  }

  const finalStyles = `${baseStyles} ${colorStyles} ${sizeStyles} ${layoutStyles}`;

  return (
    <button className={finalStyles} {...rest}>
      {children}
    </button>
  );
};

export default Button;
