import React from 'react';

type ContentWrapperProps = {
  children: React.ReactNode;
};

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children }) => (
  <div className="py-20">{children}</div>
);

export default ContentWrapper;
