import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import TextField from '../../molecules/TextField';
import SelectField from '../../molecules/SelectField';
import RadioGroup from '../../molecules/RadioGroup';
import CheckboxGroup from '../../molecules/CheckboxGroup';
import UploadField from '../../molecules/UploadField';
import TextareaField from '../../molecules/TextareaField/TextareaField';
import DatepickerField from '../../molecules/DatepickerField';
import Button from '../../atoms/Button';

import SectionHeader from '../../molecules/SectionHeader';
import SectionWrapper from '../../atoms/SectionWrapper';
import ContentWrapper from '../../atoms/ContentWrapper';

import { sendToAPI } from './sendToAPI';
import formData from './formData.json';

interface IFormData {
  name: string;
  phone: string;
  email: string;
  customerType: string;
  postCode: string;
  locationType: string;
  parking: string;
  collectionType: string;
  collectionItems: string[];
  collectionVolume: string;
  collectionDate: string;
  additionalInformation: string;
  imageUrls: File[];
}

const QuoteForm: React.FC = () => {
  const { handleSubmit, control, setValue, reset } = useForm<IFormData>({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      customerType: '',
      postCode: '',
      locationType: '',
      parking: '',
      collectionType: '',
      collectionItems: [],
      collectionVolume: '',
      collectionDate: '',
      additionalInformation: '',
      imageUrls: [],
    },
  });

  const handleDateChange = (date: Date) => {
    const formattedDate = date.toISOString().split('T')[0];
    setValue('collectionDate', formattedDate);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setValue('imageUrls', Array.from(files));
    }
  };

  const setModalContent = (content: {
    title: string;
    message: string;
    success: boolean;
  }) => {
    alert(`${content.title}\n${content.message}`);
  };

  const onSubmit = async (data: IFormData) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key as keyof IFormData];
      if (Array.isArray(value)) {
        value.forEach((item) => formData.append(key, item));
      } else {
        formData.append(key, value);
      }
    });

    const isSuccess = await sendToAPI(formData);

    if (isSuccess) {
      setModalContent({
        title: 'Success',
        message: 'Form submitted successfully!',
        success: true,
      });
      reset();
    } else {
      setModalContent({
        title: 'Error',
        message: 'There was a problem submitting the form. Please try again.',
        success: false,
      });
    }
  };

  return (
    <div className="bg-primary-200">
      <SectionWrapper>
        <ContentWrapper>
          <SectionHeader
            overline={'Quote'}
            title={'Get a quote'}
            subtitle={
              'Fill out the form below and we will get back to you within 24 hours.'
            }
          />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-8 p-8 border-2 border-primary-800 rounded-xl bg-secondary-100 max-w-screen-md"
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  id={formData.name.id}
                  label={formData.name.label}
                  type={'text'}
                  {...field}
                  placeholder={formData.name.placeholder}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  id={formData.phone.id}
                  label={formData.phone.label}
                  type={'text'}
                  {...field}
                  placeholder={formData.phone.placeholder}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  id={formData.email.id}
                  label={formData.email.label}
                  type={'text'}
                  {...field}
                  placeholder={formData.email.placeholder}
                />
              )}
            />
            <Controller
              name="customerType"
              control={control}
              render={({ field }) => (
                <SelectField
                  id={formData.customerType.id}
                  label={formData.customerType.label}
                  {...field}
                  options={formData.customerType.options}
                  placeholder={formData.customerType.placeholder}
                />
              )}
            />
            <Controller
              name="postCode"
              control={control}
              render={({ field }) => (
                <TextField
                  id={formData.postCode.id}
                  label={formData.postCode.label}
                  type={'text'}
                  {...field}
                  placeholder={formData.postCode.placeholder}
                />
              )}
            />
            <Controller
              name="locationType"
              control={control}
              render={({ field }) => (
                <SelectField
                  id={formData.locationType.id}
                  label={formData.locationType.label}
                  {...field}
                  options={formData.locationType.options}
                  placeholder={formData.locationType.placeholder}
                />
              )}
            />
            <Controller
              name="parking"
              control={control}
              render={({ field }) => (
                <SelectField
                  id={formData.parking.id}
                  label={formData.parking.label}
                  {...field}
                  options={formData.parking.options}
                  placeholder={formData.parking.placeholder}
                />
              )}
            />
            <Controller
              name="collectionType"
              control={control}
              render={({ field }) => (
                <SelectField
                  id={formData.collectionType.id}
                  label={formData.collectionType.label}
                  {...field}
                  options={formData.collectionType.options}
                  placeholder={formData.collectionType.placeholder}
                />
              )}
            />
            <Controller
              name="collectionItems"
              control={control}
              render={({ field }) => (
                <CheckboxGroup
                  id={formData.collectionItems.id}
                  label={formData.collectionItems.label}
                  selectedItems={field.value || []}
                  onChange={(newItems: string[]) => field.onChange(newItems)}
                  options={formData.collectionItems.options}
                />
              )}
            />
            <Controller
              name="collectionVolume"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  id={formData.collectionVolume.id}
                  label={formData.collectionVolume.label}
                  {...field}
                  options={formData.collectionVolume.options}
                />
              )}
            />
            <Controller
              name={'collectionDate'}
              control={control}
              render={({ field }) => (
                <DatepickerField
                  id={formData.collectionDate.id}
                  label={formData.collectionDate.label}
                  {...field}
                  placeholder={formData.collectionDate.placeholder}
                  onChange={handleDateChange}
                  value={field.value ? new Date(field.value) : null}
                />
              )}
            />
            <Controller
              name="additionalInformation"
              control={control}
              render={({ field }) => (
                <TextareaField
                  id={formData.additionalInformation.id}
                  label={formData.additionalInformation.label}
                  {...field}
                  placeholder={formData.additionalInformation.placeholder}
                />
              )}
            />
            <Controller
              name="imageUrls"
              control={control}
              render={({ field }) => (
                <UploadField
                  id={formData.imageUrls.id}
                  label={formData.imageUrls.label}
                  {...field}
                  onChange={handleImageChange}
                />
              )}
            />
            <div className="pt-8 space-x-4">
              <Button type="submit" size="large">
                Submit
              </Button>
              <Button type="reset" size="large" variant="outline">
                Reset
              </Button>
            </div>
          </form>
        </ContentWrapper>
      </SectionWrapper>
    </div>
  );
};

export default QuoteForm;
