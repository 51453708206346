import React from 'react';

type PricingFeature = {
  description: string;
};

type PricingBoxProps = {
  title: string;
  subtitle: string;
  price: string;
  frequency: string;
  features: PricingFeature[];
  buttonText: string;
  buttonLink: string;
};

const PricingBox: React.FC<PricingBoxProps> = ({
  title,
  subtitle,
  price,
  frequency,
  features,
  buttonText,
  buttonLink,
}) => (
  <div className="flex flex-col p-8 text-primary-900 bg-primary-100 rounded-lg border-2 border-primary-900 shadow-md mb-8">
    <h3 className="mb-4 text-xl uppercase font-bold">{title}</h3>
    <p className=" text-primary-800 sm:text-lg ">{subtitle}</p>
    <div className="flex justify-start items-baseline my-8">
      <div className="flex-col space-y-2">
        <h6 className="text-sm mb-2 uppercase tracking-wide">Starting From</h6>
        <div className="mr-2 text-5xl font-extrabold">{price}</div>
        <div className="text-primary-800 font-light italic tracking-tight ">
          {frequency}
        </div>
      </div>
    </div>
    <ul role="list" className="mb-8 space-y-4 text-left">
      {features.map((feature, index) => (
        <li key={index} className="flex text-md items-center space-x-3 ">
          <svg
            className="flex-shrink-0 w-5 h-5 text-primary-300 bg-primary-1000 rounded-full "
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>{feature.description}</span>
        </li>
      ))}
    </ul>
    <a
      href={buttonLink}
      className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
    >
      {buttonText}
    </a>
  </div>
);

export default PricingBox;
