import React from 'react';
import PricingBox from '../../molecules/PricingBox';
import ContentWrapper from '../../atoms/ContentWrapper';
import SectionWrapper from '../../atoms/SectionWrapper';
import SectionHeader from '../../molecules/SectionHeader';
import Layout from '../../atoms/Layout';

const PricingSection: React.FC = () => {
  const pricingPlans = [
    {
      title: 'Single Items',
      subtitle:
        'We collect large, bulky items and electronics equipment from your home or office and dispose of them responsibly.',
      price: '£35',
      frequency: 'per item',
      features: [
        { description: 'Furniture' },
        { description: 'Office Equipment' },
        { description: 'Excess waste' },
      ],
      buttonText: 'Get a Quote',
      buttonLink: '#',
    },
    {
      title: 'Van Load Collection',
      subtitle:
        'From domestic household and garden waste to commercial and industrial waste, we can collect it all at an affordable price.',
      price: '£90',
      frequency: 'per collection',
      features: [
        { description: 'Domestic waste' },
        { description: 'Commercial waste' },
        { description: 'Construction waste' },
      ],
      buttonText: 'Get a Quote',
      buttonLink: '#',
    },
    {
      title: 'Recycling Disposals',
      subtitle:
        'Let us take care of your recycling needs, saving you money on permits and time on trips to the recycling centre.',
      price: '£100',
      frequency: 'per item',
      features: [
        { description: 'Furniture' },
        { description: 'White Goods' },
        { description: 'Electronics' },
      ],
      buttonText: 'Get a Quote',
      buttonLink: '#',
    },
  ];

  return (
    <div className="bg-primary-800">
      <SectionWrapper>
        <ContentWrapper>
          <SectionHeader
            overline={'Pricing'}
            title={'Choose your collection'}
            subtitle={
              'Flexible options tailored to your needs, whether you have a single item that needs disposing, a pile of waste that needs removing immediately or need a regular collection service, we have you covered.'
            }
            className="text-primary-100"
          />
          <Layout variant="threeColumns">
            {pricingPlans.map((plan, index) => (
              <PricingBox key={index} {...plan} />
            ))}
          </Layout>
        </ContentWrapper>
      </SectionWrapper>
    </div>
  );
};

export default PricingSection;
