import React from 'react';
import { Disclosure } from '@headlessui/react';
import { HiChevronRight } from 'react-icons/hi';

interface AccordianProps {
  title: string;
  content: React.ReactNode; // This can be a string, JSX element, etc.
}

const Accordian: React.FC<AccordianProps> = ({ title, content }) => {
  return (
    <Disclosure as="div" className="mt-4">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full border-2 border-primary-800 justify-between rounded-lg px-4 py-4 text-left font-semibold  text-primary-950 hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
            <span className="flex-1">{title}</span>
            <HiChevronRight
              className={`${
                open ? 'rotate-90 transform' : ''
              } w-5 h-5 text-primary-500`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="p-4 text-md font-medium text-primary-900">
            {content}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Accordian;
