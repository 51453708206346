require('dotenv').config();
import React from 'react';
import LandingPage from './pages/LandingPage/LandingPage';

export default function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}
