import React from 'react';
import Accordian from '../../molecules/Accordian';
import SectionWrapper from '../../atoms/SectionWrapper';
import ContentWrapper from '../../atoms/ContentWrapper';
import SectionHeader from '../../molecules/SectionHeader';

const FAQSection: React.FC = () => {
  const questions = [
    {
      title: 'What areas do you cover for waste clearance and recycling?',
      content: (
        <span>
          We operate in London and Essex, providing waste clearance, recycling,
          and waste management services for both domestic and commercial
          clients. If you&apos;re unsure whether we cover your location, feel
          free to{' '}
          <a
            href="mailto:support@ecowasteclear.com"
            className="text-primary-700"
          >
            contact us
          </a>
          .
        </span>
      ),
    },
    {
      title: 'How do I book a waste collection service?',
      content: (
        <span>
          Booking is simple! You can use our{' '}
          <a href="#online-calculator" className="text-primary-700">
            Online Price Calculator
          </a>{' '}
          to get a quote and schedule a collection or contact our support team
          directly at{' '}
          <a
            href="mailto:support@ecowasteclear.com"
            className="text-primary-700"
          >
            support@ecowasteclear.com
          </a>
          .
        </span>
      ),
    },
    {
      title: 'What types of waste do you handle?',
      content: (
        <span>
          We handle various types of waste, including household, garden,
          commercial, construction, and recyclable materials. Hazardous waste
          may require special handling; please{' '}
          <a
            href="mailto:support@ecowasteclear.com"
            className="text-primary-700"
          >
            contact us
          </a>{' '}
          for details.
        </span>
      ),
    },
    {
      title: 'Do you offer same-day collections?',
      content:
        'Yes, we offer same-day collections in certain areas and depending on availability. Contact our support team to confirm.',
    },
    {
      title: 'How is the pricing determined for waste clearance?',
      content: (
        <span>
          Pricing depends on the type and volume of waste, as well as the
          service plan chosen. You can use our{' '}
          <a href="#online-calculator" className="text-primary-700">
            Online Price Calculator
          </a>{' '}
          for an instant quote or contact us for custom solutions.
        </span>
      ),
    },
    {
      title: 'Are you committed to eco-friendly waste disposal?',
      content: (
        <span>
          Absolutely! We prioritize eco-friendly methods, recycling applicable
          materials, and responsible disposal aligned with environmental
          stewardship. Learn more about our{' '}
          <a href="#commitment-to-sustainability" className="text-primary-700">
            commitment to sustainability
          </a>
          .
        </span>
      ),
    },
    {
      title: 'Can I reschedule or cancel a collection?',
      content: (
        <span>
          Yes, you can reschedule or cancel a collection with a minimum of 24
          hours&apos; notice. Please email us at{' '}
          <a
            href="mailto:support@ecowasteclear.com"
            className="text-primary-700"
          >
            support@ecowasteclear.com
          </a>{' '}
          to make changes.
        </span>
      ),
    },
    {
      title: 'What is your refund policy for waste management services?',
      content: (
        <span>
          If you&apos;re unhappy with our service, please email us within{' '}
          <a
            href="mailto:support@ecowasteclear.com"
            className="text-primary-700"
          >
            7 days
          </a>{' '}
          of the collection, and we&apos;ll discuss an appropriate resolution,
          including possible refunds.
        </span>
      ),
    },
  ];

  return (
    <SectionWrapper>
      <ContentWrapper>
        <SectionHeader
          overline={'FAQ'}
          title={"We've got green solutions to your rubbish problems"}
          subtitle={
            'Here are some of our FAQs. If you have any other questions you’d like answered please feel free to email us.'
          }
        />
        {questions.map((question, index) => (
          <Accordian
            key={index}
            title={question.title}
            content={question.content}
          />
        ))}
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default FAQSection;
